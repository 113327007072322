import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

import { FC } from "react";


export interface SectionHero4Props {
  className?: string;
}

const SectionHero4: FC<SectionHero4Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero relative ${className} `}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
       
      </div>

      <div className="hidden lg:block z-10 mb-12  w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};


export default SectionHero4;
