import React from "react";
import why305 from "images/rightimg.jpeg";
import Badge from "shared/Badge/Badge";

const WhyLevel305 = () => {
  return (
    <div className=" text-[#3B3B3B] ">
      <div className="flex flex-col gap-12 md:gap-0 md:flex-row justify-between items-center">
       
        <div className="space-y-8 max-w-[50%]">
          <h2 className="text-[2rem] font-bold leading-relaxed">Why Kings Car Rentals</h2>

       
          <Badge name="Fleet" />
          <div className="flex items-start space-x-4">
            <div>
              <span className="text-xl font-bold text-[#3B3B3B]">
                Diverse Fleet of Vehicles
              </span>
              <p className="text-[#3B3B3B] max-w-[80%]">
                We also offer luxury vehicles for those special occasions when
                you want to travel in style.
              </p>
            </div>
          </div>

          <Badge color="green" name="24*7 Support" />
          <div className="flex items-start space-x-4">
            <div>
              <h3 className="text-xl font-bold text-[#3B3B3B]">
                Exceptional Customer Service
              </h3>
              <p className="text-[#3B3B3B] max-w-[80%]">
                We pride ourselves on providing a hassle-free rental experience,
                ensuring you hit the road quickly and confidently.
              </p>
            </div>
          </div>

        
          <Badge color="red" name="Secure"/>
          <div className="flex items-start space-x-4">
           
            <div>
              <h3 className="text-xl font-bold text-[#3B3B3B]">Affordable Pricing</h3>
              <p className="text-[#3B3B3B] max-w-[80%]">
                We offer transparent pricing with no hidden fees. Check out our
                special offers and discounts to get the best deal on your next
                rental.
              </p>
            </div>
          </div>
        </div>

        <div className="">
          <img
            src={why305}
            alt="Luxury Car"
            className="rounded-none object-cover h-[650px]"
          />
        </div>
      </div>
    </div>
  );
};

export default WhyLevel305;
