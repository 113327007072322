import clientSay1 from "images/client1.avif";
import clientSay4 from "images/client2.avif";
import clientSay5 from "images/client3.avif";
import clientSay6 from "images/client4.avif";
import clientSay7 from "images/client5.avif";
import clientSay8 from "images/client6.avif";


export const globalJson = {
  prod_company_id: 111,
  dev_company_id: 111,

  // apiUtil auth header key
  authToken: "Bearer 168|3oLFn8OniSxk1UBTO3eQeGXMuViDTsZqAwhalwWm",
  companyId: "7514a219-48ce-439e-a2c5-6f38980cee1a",
  stripePublishableKey:
    "pk_live_51LdnoABqYa4ICTHoC6CjEgImHYOvUXXPs8psg8jZqXPxRwBt99nQEWtFFJj2n3ublkCJa0S8pYzDtvStF2JxUPzU00uWCskvlb",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc",
  residenceStateCode: "AZ",

  //email confirmation admin token
  adminEmailToken:
    "XR85jFku2zMMqvsYmAdkrk1lm6pozj7eFXetRHLNSSsLxRLnAHgY3dPaVsE27OK8cIbnhFQmncWxVjS1XLAhneFEFpwVVeaNE7hMOym2vrhzdKSmxn99V0fLvVJyZLPT",
  websiteLink: "https://King Car Rentals-dev.1now.app",
  sender: "King Car Rentals",
  ccEmail: "King Car Rentals@gmail.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_2xca7kj",
  util_templateId: "template_smckeo7",
  util_userId: "ATdsFr5wumje11YkV",
  util_privateKey: "TJyyV_e7nNOGPKbyyjpMB",
  util_contact_templateId: "template_9dsuz0w",

  // apiUtil getBonzahToken
  client_secret_dev: "",
  client_id_dev: "",
  client_secret_prod: "",
  client_id_prod: "",

  about_us_para:
    "Welcome to Why Kings Car Rentals LLC, where we elevate your travel experience with unmatched quality and service. As a leading rental car provider, we are dedicated to delivering a smooth and exceptional journey for every traveler. Recognizing that every trip is distinct, we aim to be your reliable partner on the road. Our carefully curated fleet includes everything from elegant sedans to roomy SUVs, ensuring we have the ideal vehicle for any occasion.",

  clientSays_arr: [
    {
      id: 1,
      clientName: "Melanie V.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Dominque was an amazing host. Great communication, car was as listed. Very clean. 100% recommend.",
      img: clientSay1,
    },
    {
      id: 2,
      clientName: "Angela G.",
      clientAddress: "LondDodge Grand Caravan 2019",
      content:
        "Easy to access and get on road. Great ride and host!",
      img: clientSay4,
    },
    {
      id: 3,
      clientName: "Daniel D.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Car quality: excellent Cleanliness: Excellent Overall: Excellent",
      img: clientSay5,
    },
    {
      id: 4,
      clientName: "Valaree N.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Pick up location was perfect and easy to find. Truck was in good clean condition and worked great for us. Host was very easy to communicate with. Thank you.",
      img: clientSay6,
    },
    {
      id: 5,
      clientName: "Christian L.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Best experience in Ketchikan and I travel here a lot",
      img: clientSay7,
    },
    {
      id: 6,
      clientName: "J L.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Great people, great Car. No issues and they went above and beyond.",
      img: clientSay8,
    },
  ],

  //social links
  facebook:
    "https://www.facebook.com/",
  instagram:
    "https://www.instagram.com/",

  //contact
  address: "6702 Ravenwood Drive, Ketchikan, Alaka 99901",
  addressLink:
    "https://www.google.com/maps/place/4830+W+Kennedy+Blvd,+Tampa,+FL+33609,+USA/data=!4m2!3m1!1s0x88c2c2e4b61fbe87:0x7fa31f86943d0895?sa=X&ved=1t:242&ictx=111",
  phone: "+1 907-617-3765", //pending
  email: "dominiqueking6@gmail.com", //pending
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-na1.hs-scripts.com/46776911.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
