import React, { FC, Fragment } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";

import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { useLocation, useNavigate } from "react-router-dom";
import AvatarDropdown from "./AvatarDropdown";
import { useData } from "data/data-provider";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { user, setIsAuthPanelOpen } = useData();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-2 relative flex justify-between items-center">
        <div className="flex justify-start items-center space-x-4 sm:space-x-10">
          <Logo />
        </div>
        <div className="hidden md:flex items-center ml-auto">
          <Navigation />
        </div>
        {location.pathname !== "/" && (
          <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
            <HeroSearchForm2MobileFactory />
          </div>
        )}

        <div className=" flex flex-shrink-0 items-center justify-end lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            <div className="px-2" />
          </div>
          <div className="flex lg:hidden items-center">
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
